import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import Service from '../domain/Service';
import { useOktaAuth } from '@okta/okta-react';
import ServiceCard from './ServiceCard';

const ServiceList: Array<Service> =
  [
    {
      "title": "STRUP",
      "abstract": "現場をどこからでも「見える化」する",
      "thumbnail_file_name": "STRUP.png",
      "pamphlet_name": "strup.pdf",
    },
    {
      "title": "GT-A",
      "abstract": "クラウド(AWS)上のサーバ構築から運用、AWSアカウントの運用まで、AWSに関わる作業を全てお任せ！",
      "thumbnail_file_name": "GT-A.png",
      "pamphlet_name": "GT-Aサービスリーフレット.pdf",
    },
    {
      "title": "GT-NAS",
      "abstract": "シンプルかつ安心してお使いいただけるファイルサーバを追求し、形にしました。",
      "thumbnail_file_name": "GT-NAS.png",
      "pamphlet_name": "GT-NASサービスリーフレット.pdf",
    },
    {
      "title": "GT-O",
      "abstract": "味の素グループ特化のOracle Database専用プライベートクラウドサービス",
      "thumbnail_file_name": "CT-O.png",
      "pamphlet_name": "GT-Oサービスリーフレット.pdf",
    },
    {
      "title": "GT-S",
      "abstract": "煩雑なシステム作業や確認作業自動化を実現します",
      "thumbnail_file_name": "GT-S.png",
      "pamphlet_name": "GT-Sサービスリーフレット.pdf",
    },
    {
      "title": "GT-VDI",
      "abstract": "在宅ワークへのセキュリティ向上！",
      "thumbnail_file_name": "GT-VDI.png",
      "pamphlet_name": "GT-VDIサービスリーフレット.pdf",
    },
    {
      "title": "GT-V(味の素グループ版)",
      "abstract": "プライベートクラウドサービスの構築から運用までお任せ！ ",
      "thumbnail_file_name": "GT-V.png",
      "pamphlet_name": "GT-Vサービスリーフレット(味の素グループ版).pdf",
    },
    {
      "title": "GT-V(味の素版)",
      "abstract": "プライベートクラウドサービスの構築から運用までお任せ！",
      "thumbnail_file_name": "GT-V.png",
      "pamphlet_name": "GT-Vサービスリーフレット(味の素版).pdf",
    },
    {
      "title": "GT-WAN",
      "abstract": "クラウドサービス利用を快適に！",
      "thumbnail_file_name": "GT-WAN.png",
      "pamphlet_name": "GT-WANサービスリーフレット.pdf",
    },
  ];

interface UrlList {
  [fileName: string] : {url: string}
}

interface ServicesProps {
  urlList: UrlList
}

const Services = (props: ServicesProps) => {
  const { authState } = useOktaAuth();

  return (
    <Container fixed>
      <Typography variant="h4">NST Services</Typography>
      <Grid container spacing={2}>
        {ServiceList.map((service: Service) => {
          return (
            <ServiceCard urlList={props.urlList} service={service} />
          )
        })}
      </Grid>
    </Container>
  );
}

export default Services;